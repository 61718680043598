<template>
    <div>
        <v-title title="Customer Groups - Add"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Add customers group</h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-7">
                    <customer-group-form class="mt-n4" :action="action" message="Customer Group was added"></customer-group-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import CustomerGroupForm from '@/components/elements/customers/CustomerGroupForm'

export default {
    name: 'AddCustomersGroup',
    components: { CustomerGroupForm },
    data () {
        return {
            action: CONFIG.API.ROUTES.CUSTOMERS.GROUPS.ADD,
        }
    },
}
</script>
